<template>
    <div style="height: 100%;">
        <l-map ref="map" class="map" id="theMap" :zoom="karte.zoom" :center="karte.center" :options="karte.mapOptions">
            <l-tile-layer :name="karte.selectedTile.name" :url="karte.selectedTile.url" :attribution="karte.selectedTile.attribution" layer-type="base" />
            <v-marker-cluster ref="cluster" :options="karte.clusterOptions">
                <l-marker :id="'content-marker-'+content.id" :ref="'content-marker-'+content.id" v-for="content in contentsWithLocations" :key="content.id" :lat-lng="content.latLng" :icon="getSymbolIcon(content)" @click="highlightThisContent(content)" @keyup.enter="highlightThisContent(content)" @keyup.space="highlightThisContent(content)">
                    <l-popup class="institution-popup">
                        <institution-marker :content="content" />
                    </l-popup>
                </l-marker>
            </v-marker-cluster>
        </l-map>
    </div>
</template>
<script>
import 'leaflet/dist/leaflet.css';
import { latLng, icon } from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { getFieldValues } from '@/utils/helpers';
import MarkerCluster from './markerClusterExtension';
import InstitutionMarker from './MarkerInstitutionContent.vue';

export default {
    name: 'MultipleMapView',
    components: {
        LTileLayer,
        LMarker,
        LPopup,
        LMap,
        InstitutionMarker,
        'v-marker-cluster': MarkerCluster,
    },
    props: {
        height: {
            type: String,
            default: '700px',
        },
        contents: {
            type: Array,
            required: true,
            default: null,
        },
    },
    data() {
        return {
            L: window.L,
            map: null,
            karte: {
                modus: false,
                zoom: 12,
                center: latLng(54.35879047390532, 10.169230974009567),
                bounds: this.theBounds,
                mapOptions: {
                    zoomSnap: 0.5,
                    zoomControl: true,
                    touchZoom: true,
                    dragging: true,
                    doubleClickZoom: true,
                    scrollWheelZoom: true,
                    keyboard: true,
                },
                clusterOptions: {
                    spiderfyOnMaxZoom: true,
                    showCoverageOnHover: false,
                },
                selectedTile: {
                    url: "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
                    attribution: "&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>"
                },
            },
        };
    },
    computed: {
        contentsWithLocations() {
            var locationContents = [];

            if (this.contents != null) {
                for (var i = 0; i < this.contents.length; i++) {
                    this.getLatLongForContent(this.contents[i]);
                    if (this.contents[i].latLng != null) {
                        locationContents.push(this.contents[i]);
                    }
                }
            }
            return locationContents;
        },
        markerData() {
            if (this.addressObj != null && Object.prototype.hasOwnProperty.call(this.addressObj, 'latitude') && this.addressObj.latitude != null && Object.prototype.hasOwnProperty.call(this.addressObj, 'longitude') && this.addressObj.longitude != null && Object.prototype.hasOwnProperty.call(this.addressObj, 'street') && this.addressObj.street != null && Object.prototype.hasOwnProperty.call(this.addressObj, 'zipcode') && this.addressObj.zipcode != null && Object.prototype.hasOwnProperty.call(this.addressObj, 'city') && this.addressObj.city != null) {

                var mapMarker = { lat: this.addressObj.latitude, long: this.addressObj.longitude, street: this.addressObj.street, zipcode: this.addressObj.zipcode, city: this.addressObj.city, latLng: latLng(this.addressObj.latitude, this.addressObj.longitude) };
                return mapMarker;
            }
            return null;
        },
    },
    mounted() {
        this.$nextTick(() => {
            // Get the Leaflet map instance
            this.map = this.$refs.map.mapObject;
        });
    },
    methods: {
        getSymbolIcon(content) {
            var customIcon = null;
            if (Object.prototype.hasOwnProperty.call(content, 'highlight') && content.highlight == true) {
                customIcon = icon({
                    iconUrl: '/assets/icons/map/marker.svg',
                    shadowUrl: '',
                    iconSize: [60, 60],
                    /* size of the icon */
                    iconAnchor: [30, 53],
                    /* point of the icon which will correspond to marker's location */
                    popupAnchor: [0, -48],
                });
                return customIcon;
            } else {
                customIcon = icon({
                    iconUrl: '/assets/icons/map/marker-normal.svg',
                    shadowUrl: '',
                    iconSize: [50, 50],
                    /* size of the icon */
                    iconAnchor: [25, 43],
                    /* point of the icon which will correspond to marker's location */
                    popupAnchor: [0, -48],
                })
                return customIcon;
            }
        },
        getLatLongForContent(content) {
            if (content == null) {
                return;
            }

            var vals = null;
            vals = getFieldValues(content, 'adresse');

            /*check if there is a latitude and a longitude*/
            if (vals != null && Object.prototype.hasOwnProperty.call(vals, 'latitude') && Object.prototype.hasOwnProperty.call(vals, 'latitude')) {
                content.latLng = latLng(vals.latitude, vals.longitude);
                content.lat = vals.latitude;
                content.long = vals.longitude;
                return content.latLng;
            }
            content.lat = null;
            content.long = null;
            content.latLng = null;
            return null;
        },
        highlightThisContent(content) {
            this.$emit('highlight', { sender: 'map', content: content });
        },
        openPopup(content) {
            if ('content-marker-' + content.id in this.$refs) {
                var marker = this.$refs['content-marker-' + content.id][0].mapObject;
                var parentCluster = marker.__parent;
                /*Check if popup is already open*/
                if (marker.getPopup().isOpen()) {
                    return;
                }
                /*Zoom in on marker and open popup*/
                this.map.setView(marker.getLatLng(), 19);

                /*Check if marker is part of a cluster and spiderfy it if it is */
                if (parentCluster instanceof L.MarkerCluster) {
                    /*Get all markers with the same coordinates*/
                    var markers = parentCluster.getAllChildMarkers().filter(function(m) {
                        return m.getLatLng().equals(marker.getLatLng());
                    });

                    /*Only spiderfy the cluster if there are other markers with the same coordinates*/
                    if (markers.length > 1 && !parentCluster.spiderfied) {
                        parentCluster.spiderfy();
                    }
                }
                
                this.$nextTick(() => {
                    marker.openPopup();
                });
            }
        }
    },
};
</script>
<style lang="scss" scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet-container .leaflet-marker-pane img {
    -webkit-filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, .5));
}

body[dir="rtl"] {
    .marker-cluster {
        margin-right: 5px !important;
    }
}
</style>